export const pizzas = [
  {
    pizzaBox: `${process.env.PUBLIC_URL}/images/slider/boxes/pizza-shrek-box.webp`,
    pizzaBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/pizza-shrek-box-mobile.png`,
    pizzaBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/pizza-shrek-box.png`,
    modalPizza: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/pizza-shrek.png`,
    id: -1,
    pizza: `${process.env.PUBLIC_URL}/images/slider/pizzas/pizza-shrek.webp`,
    pizzaMobile: `${process.env.PUBLIC_URL}/images/slider/pizzas/mobile/pizza-shrek.png`,
    pizzaFallback: `${process.env.PUBLIC_URL}/images/slider/pizzas/png/pizza-shrek.png`,
    alt:
      "Guseppe o smaku Chorizo & Green Pepper z limitowanej edycji, to OGRomnie pyszna pizza inspirowana kuchnia Shreka. Na jej wyjatkowy smak sktada sie kompozycja: wyrazistego salami chorizo, sera, aromatycznego sosu pomidorowo-szpinakowego oraz warzyw: zielonej papryki i czerwonej cebulki. Dla wszystkich mitośników Guseppe i sympatycznego Ogra!",
    path: "/pizza-chorizo-green-pepper",
    name: (
      <p>
        Chorizo<br/>& Green Pepper
      </p>
    ),
    pizzaSelectedFooterItem: `${process.env.PUBLIC_URL}/images/slider/selected/pizzas/shrek-selected.png`
  },
  {
    pizzaBox: `${process.env.PUBLIC_URL}/images/slider/boxes/pizza-szynka-pieczarka-pudelko.webp`,
    pizzaBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/pizza-szynka-pieczarka-pudelko.png`,
    pizzaBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/pizza-szynka-pieczarka-pudelko.png`,
    modalPizza: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/pizza-szynka-pieczarka.png`,
    id: 0,
    pizza: `${process.env.PUBLIC_URL}/images/slider/pizzas/pizza-szynka-pieczarka.webp`,
    pizzaMobile: `${process.env.PUBLIC_URL}/images/slider/pizzas/mobile/pizza-szynka-pieczarka.png`,
    pizzaFallback: `${process.env.PUBLIC_URL}/images/slider/pizzas/png/pizza-szynka-pieczarka.png`,
    alt:
      "Ham & mushroom - pyszna pizza z szynką i pieczarkami na puszystym ciescie",
    path: "/pizza-szynka-pieczarka",
    name: (
      <p>
        Ham <br />& Mushroom
      </p>
    ),
    pizzaSelectedFooterItem: `${process.env.PUBLIC_URL}/images/slider/selected/pizzas/ham-mushroom-selected.png`
  },
  {
    pizzaBox: `${process.env.PUBLIC_URL}/images/slider/boxes/pizza-4-sery-pudelko.webp`,
    pizzaBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/pizza-4-sery-pudelko.png`,
    pizzaBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/pizza-4-sery-pudelko.png`,
    modalPizza: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/pizza-4-sery.png`,
    id: 1,
    pizza: `${process.env.PUBLIC_URL}/images/slider/pizzas/pizza-4-sery.webp`,
    pizzaFallback: `${process.env.PUBLIC_URL}/images/slider/pizzas/png/pizza-4-sery.png`,
    path: "/pizza-4-sery",
    alt:
      "4 cheese - pizza z kompozycja sera edamskiego, mozzarelli, tylzyckiego i sera plesniowego na puszystym ciescie ",
    name: <p>4 Cheese</p>,
    pizzaSelectedFooterItem: `${process.env.PUBLIC_URL}/images/slider/selected/pizzas/cheese-selected.png`
  },
  {
    pizzaBox: `${process.env.PUBLIC_URL}/images/slider/boxes/pizza-salami-pudelko.webp`,
    pizzaBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/pizza-salami-pudelko.png`,
    pizzaBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/pizza-salami-pudelko.png`,
    modalPizza: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/pizza-salami.png`,
    id: 2,
    pizza: `${process.env.PUBLIC_URL}/images/slider/pizzas/pizza-salami.webp`,
    pizzaFallback: `${process.env.PUBLIC_URL}/images/slider/pizzas/png/pizza-salami.png`,
    path: "/pizza-salami",
    alt: "Salami - pizza o wyrazistym smaku ostrego salami na grubym ciescie",
    name: <p>Salami</p>,
    pizzaSelectedFooterItem: `${process.env.PUBLIC_URL}/images/slider/selected/pizzas/salami-selected.png`
  },
  {
    pizzaBox: `${process.env.PUBLIC_URL}/images/slider/boxes/pizza-kebab-pudelko.webp`,
    pizzaBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/pizza-kebab-pudelko.png`,
    pizzaBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/pizza-kebab-pudelko.png`,
    modalPizza: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/pizza-kebab.png`,
    id: 3,
    path: "/pizza-kebab",
    pizza: `${process.env.PUBLIC_URL}/images/slider/pizzas/pizza-kebab.webp`,
    pizzaFallback: `${process.env.PUBLIC_URL}/images/slider/pizzas/png/pizza-kebab.png`,
    alt:
      "Kebab - pizza w stylu tureckim z miesem wolowym, paprykami, grillowanym baklazanem na puszystym ciescie ",
    name: <p>Kebab</p>,
    pizzaSelectedFooterItem: `${process.env.PUBLIC_URL}/images/slider/selected/pizzas/Kebab-selected.png`
  },
  {
    pizzaBox: `${process.env.PUBLIC_URL}/images/slider/boxes/pizza-kurczak-curry-pudelko.webp`,
    pizzaBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/pizza-kurczak-curry-pudelko.png`,
    pizzaBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/pizza-kurczak-curry-pudelko.png`,
    modalPizza: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/pizza-kurczak-curry.png`,
    id: 4,
    path: "/pizza-kurczak-curry",
    pizza: `${process.env.PUBLIC_URL}/images/slider/pizzas/pizza-kurczak-curry.webp`,
    pizzaFallback: `${process.env.PUBLIC_URL}/images/slider/pizzas/png/pizza-kurczak-curry.png`,
    alt:
      "Chicken curry - pizza o tajskim smaku z soczystym kurczakiem w przyprawie curry na puszystym ciescie",
    name: <p>Chicken Curry</p>,
    pizzaSelectedFooterItem: `${process.env.PUBLIC_URL}/images/slider/selected/pizzas/chicken-curry-selected.png`
  },
  {
    pizzaBox: `${process.env.PUBLIC_URL}/images/slider/boxes/pizza-ham-chorizo-pudelko.webp`,
    pizzaBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/pizza-ham-chorizo-pudelko.png`,
    pizzaBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/pizza-ham-chorizo-pudelko.png`,
    modalPizza: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/pizza-ham-chorizo.png`,
    id: 5,
    path: "/pizza-szynka-chorizo",
    pizza: `${process.env.PUBLIC_URL}/images/slider/pizzas/pizza-ham-chorizo.webp`,
    pizzaFallback: `${process.env.PUBLIC_URL}/images/slider/pizzas/png/pizza-ham-chorizo.png`,
    alt:
      "Chorizo - pizza w stylu amerykanskim z szynką, chorizo na puszystym ciescie",
    name: <p>Ham <br />& Chorizo</p>,
    pizzaSelectedFooterItem: `${process.env.PUBLIC_URL}/images/slider/selected/pizzas/ham-chorizo-selected.png`
  },
  {
    pizzaBox: `${process.env.PUBLIC_URL}/images/slider/boxes/pizza-szynka-sos-czosnkowy-pudelko.webp`,
    pizzaBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/pizza-szynka-sos-czosnkowy-pudelko.png`,
    pizzaBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/pizza-szynka-sos-czosnkowy-pudelko.png`,
    modalPizza: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/pizza-szynka-sos-czosnkowy.png`,
    id: 7,
    path: "/pizza-szynka-sos-czosnkowy",
    pizza: `${process.env.PUBLIC_URL}/images/slider/pizzas/pizza-szynka-sos-czosnkowy.webp`,
    pizzaFallback: `${process.env.PUBLIC_URL}/images/slider/pizzas/png/pizza-szynka-sos-czosnkowy.png`,
    alt:
      "Ham & garlic sauce - pizza z szynka i aromatycznym sosem czosnkowym na puszystym ciescie",
    name: (
      <p>
        Ham <br />& Garlic Sauce
      </p>
    ),
    pizzaSelectedFooterItem: `${process.env.PUBLIC_URL}/images/slider/selected/pizzas/ham-garlic-sauce-selected.png`
  },
  {
    pizzaBox: `${process.env.PUBLIC_URL}/images/slider/boxes/pizza-szynka-pudelko.webp`,
    pizzaBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/pizza-szynka-pudelko.png`,
    pizzaBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/pizza-szynka-pudelko.png`,
    modalPizza: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/pizza-szynka.png`,
    id: 8,
    path: "/pizza-szynka",
    pizza: `${process.env.PUBLIC_URL}/images/slider/pizzas/pizza-szynka.webp`,
    pizzaFallback: `${process.env.PUBLIC_URL}/images/slider/pizzas/png/pizza-szynka.png`,
    alt:
      "Ham - pizza klasyczna z szynka, cebula i aromatycznymi ziolami na puszystym ciescie",
    name: <p>Ham</p>,
    pizzaSelectedFooterItem: `${process.env.PUBLIC_URL}/images/slider/selected/pizzas/ham-selected.png`
  },
  // {
  //   pizzaBox: `${process.env.PUBLIC_URL}/images/slider/boxes/pizza-chicken-grilled-vegetables-pudelko.webp`,
  //   pizzaBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/pizza-chicken-grilled-vegetables-pudelko.png`,
  //   pizzaBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/pizza-chicken-grilled-vegetables-pudelko.png`,
  //   modalPizza: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/pizza-chicken-grilled-vegetables.png`,
  //   id: 9,
  //   path: "/pizza-kurczak-grillowane-warzywa",
  //   pizza: `${process.env.PUBLIC_URL}/images/slider/pizzas/pizza-chicken-grilled-vegetables.webp`,
  //   pizzaFallback: `${process.env.PUBLIC_URL}/images/slider/pizzas/png/pizza-chicken-grilled-vegetables.png`,
  //   alt:
  //     "Chicken & Grilled Vegetables - pizza łącząca wyraziście doprawionego kurczaka, grillowane warzywa",
  //   name: <p>Chicken & Grilled Vegetables</p>,
  // },

  // {
  //   pizzaBox: `${process.env.PUBLIC_URL}/images/slider/boxes/pizza-4-sery-hot-pudelko.webp`,
  //   pizzaBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/pizza-4-sery-hot-pudelko.png`,
  //   pizzaBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/pizza-4-sery-hot-pudelko.png`,
  //   modalPizza: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/pizza-4-sery-hot.png`,
  //   id: 10,
  //   pizza: `${process.env.PUBLIC_URL}/images/slider/pizzas/pizza-4-sery-hot.webp`,
  //   pizzaFallback: `${process.env.PUBLIC_URL}/images/slider/pizzas/png/pizza-4-sery-hot.png`,
  //   path: "/pizza-4-sery-hot",
  //   alt:
  //     "4 Cheese HOT to pikantna pizza z pyszną kompozycją serów: Edamskiego, Mozzarella, Tylżyckiego i sera pleśniowego. ",
  //   name: <p>4 Cheese HOT</p>,
  // },
  {
    pizzaBox: `${process.env.PUBLIC_URL}/images/slider/boxes/pizza-hawaii-pudelko.webp`,
    pizzaBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/pizza-hawaii-pudelko.png`,
    pizzaBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/pizza-hawaii-pudelko.png`,
    modalPizza: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/pizza-hawaii.png`,
    id: 11,
    pizza: `${process.env.PUBLIC_URL}/images/slider/pizzas/pizza-hawaii.webp`,
    pizzaFallback: `${process.env.PUBLIC_URL}/images/slider/pizzas/png/pizza-hawaii.png`,
    path: "/pizza-hawaii",
    alt:
      "Guseppe Hawaii to pizza, której smak tworzy kompozycja delikatnej szynki, słodkiego ananasa, ciągnącego się sera i wyrazistego pomidorowego sosu.",
    name: <p>Hawaii</p>,
    pizzaSelectedFooterItem: `${process.env.PUBLIC_URL}/images/slider/selected/pizzas/hawaii-selected.png`
  },
  {
    pizzaBox: `${process.env.PUBLIC_URL}/images/slider/boxes/pizza-salami-cheddar-pudelko.webp`,
    pizzaBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/pizza-salami-cheddar-pudelko.png`,
    pizzaBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/pizza-salami-cheddar-pudelko.png`,
    modalPizza: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/pizza-salami-cheddar.png`,
    id: 12,
    pizza: `${process.env.PUBLIC_URL}/images/slider/pizzas/pizza-salami-cheddar.webp`,
    pizzaFallback: `${process.env.PUBLIC_URL}/images/slider/pizzas/png/pizza-salami-cheddar.png`,
    path: "/pizza-salami-cheddar",
    alt:
      "Guseppe Salami & Cheddar Sauce to wyjątkowe połączenie lekko pikantnego salami i podwójnej porcji sera cheddar - na obłożeniu i w sosie. Całość wzbogacona zieloną papryką i doprawiona aromatycznymi ziołami. Wyraziste salami oraz intensywnie chedarowy sos sprawiają, że ta pizza to przepysznie doznanie w każdym kęsie. ",
    name: <p>Salami<br />& Cheddar Sauce</p>,
    pizzaSelectedFooterItem: `${process.env.PUBLIC_URL}/images/slider/selected/pizzas/salami-and-cheddar-sauce-selected.png`
  },
];

export const baguettes = [
  {
    id: 1,
    path: "/baguette-tomato-cheese",
    name: <p>Tomato<br />& Cheese</p>,
    baguetteBox: `${process.env.PUBLIC_URL}/images/slider/boxes/baguette-tomato-cheese-box.webp`,
    baguetteBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/baguette-tomato-cheese-box-mobile.png`,
    baguetteBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/baguette-tomato-cheese-box.png`,
    modalBaguette: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/baguette-tomato-cheese.png`,
    alt: "Potrójnie serowa zapiekanka z chrupiącą bagietką, mozzarellą, cheddarem, serem edamskim i aromatycznym pomidorem. Łagodna w smaku propozycja dla smakoszy zapiekanek i ciagnącego się sera! Produkt odpowiedni dla wegetarian.",
    baguette: `${process.env.PUBLIC_URL}/images/slider/baguettes/baguette-tomato-cheese.webp`,
    baguetteFallback: `${process.env.PUBLIC_URL}/images/slider/baguettes/png/baguette-tomato-cheese.png`,
    baguetteSelectedFooterItem: `${process.env.PUBLIC_URL}/images/slider/selected/baguette/tomato-and-cheese-selected.png`,
  },
  {
    id: 2,
    path: "/baguette-mushroom",
    name: <p>Mushroom</p>,
    baguetteBox: `${process.env.PUBLIC_URL}/images/slider/boxes/baguette-mushroom-box.webp`,
    baguetteBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/baguette-mushroom-box-mobile.png`,
    baguetteBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/baguette-mushroom-box.png`,
    modalBaguette: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/baguette-mushroom.png`,
    alt: "Zapiekanki z delikatnymi pieczarkami, ciągnącym się serem i dodatkiem pomidora. Wszystko to ułożone jest na chrupiącej bagietce z sosem śmietanowym i doprawione aromatycznymi ziołami. Idealna propozycja dla smakoszy klasycznych zapiekanek.",
    baguette: `${process.env.PUBLIC_URL}/images/slider/baguettes/baguette-mushroom.webp`,
    baguetteFallback: `${process.env.PUBLIC_URL}/images/slider/baguettes/png/baguette-mushroom.png`,
    baguetteSelectedFooterItem: `${process.env.PUBLIC_URL}/images/slider/selected/baguette/mushroom-selected.png`,
  },
  {
    id: 3,
    path: "/baguette-salami",
    name: <p>Salami</p>,
    baguetteBox: `${process.env.PUBLIC_URL}/images/slider/boxes/baguette-salami-box.webp`,
    baguetteBoxMobile: `${process.env.PUBLIC_URL}/images/slider/boxes/mobile/baguette-salami-box-mobile.png`,
    baguetteBoxFallback: `${process.env.PUBLIC_URL}/images/slider/boxes/png/baguette-salami-box.png`,
    modalBaguette: `${process.env.PUBLIC_URL}/images/BurgerMenuModal/png/baguette-salami.png`,
    alt: "Zapiekanki z wyrazistym salami, ciągnącym się serem i dodatkiem warzyw. Wszystko to ułożone jest na chrupiącej bagietce z pomidorowym sosem i doprawione aromatycznymi ziołami. Idealna propozycja dla smakoszy smakoszy zapiekanek, a także pizzy z salami.",
    baguette: `${process.env.PUBLIC_URL}/images/slider/baguettes/baguette-salami.webp`,
    baguetteFallback: `${process.env.PUBLIC_URL}/images/slider/baguettes/png/baguette-salami.png`,
    baguetteSelectedFooterItem: `${process.env.PUBLIC_URL}/images/slider/selected/baguette/salami-selected.png`,
  },
]

export const products = [
  ...pizzas.map((pizza) => ({...pizza, productType: "pizza"})),
  ...baguettes.map((baguette) => ({...baguette, productType: "baguette"}))
]

export const MOBILE_WIDTH = 1023;
export const NAVBAR_MOBILE_WIDTH = 1200;

export const baguettesColors = {
  "mushroom": "#E5A342",
  "salami": "#E30A15",
  "tomatoCheese": "#F49C00",
}
