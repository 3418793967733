/* eslint-disable array-callback-return */
import React, {useEffect, useState} from "react";
import "./NavBar.scss";
import {useHistory} from "react-router-dom";
import useWindowSize from "../../Hooks/UseWindowSize";
import PizzasList from "../BurgerMenuModal/ProductLists/PizzasList";
import PizzasModal from "../Modal/PizzasModal/PizzasModal";
import {Button, Popover, PopoverBody} from "reactstrap";
import DropdownMenu from "../common/DropdownMenu/DropdownMenu";
import BaguettesModal from "../Modal/BaguetteModal/BaguettesModal";
import BaguettesList from "../BurgerMenuModal/ProductLists/BaguettesList";
import {NAVBAR_MOBILE_WIDTH} from "../../constants";

// import g2aMenu from "../../pages/G2A/Main/images/g2a_menu.webp";

const navigationPositions = [ // Optional args: additionalDesktopStyle (object) / additionalMobileStyle (object) / hoverEffect (bool)
  { mobileContent: "Pizze", desktopContent: "Pizze", id: 1, link: undefined },
  { mobileContent: "Zapiekanki", desktopContent: "Zapiekanki", id: 2, link: undefined },
  { mobileContent: "Znajdź sklep", desktopContent: "Znajdź sklep", id: 3, link: "/znajdz-sklep" },
  {
    mobileContent: "Instrukcja pieczenia", desktopContent: "Instrukcja pieczenia", id: 4, submenus: [
      {
        mobileContent: "Instrukcja pieczenia Pizza",
        desktopContent: "Pizza",
        id: 1,
        link: "/jak-upiec"
      },
      {
        mobileContent: "Instrukcja pieczenia Zapiekanki",
        desktopContent: "Zapiekanki",
        id: 2,
        link: "/jak-upiec#baguette"
      },
    ]
  },
  { mobileContent: "Dowiedz się więcej", desktopContent: "Dowiedz się więcej", id: 5, link: "dowiedz-sie-wiecej" },
  { mobileContent: "Kontakt", desktopContent: "Kontakt", id: 6, link: "/#footer" },
  // { mobileContent: "Gra", desktopContent: "Gra", id: 6 },
  // { mobileContent: <img alt="g2a" src={g2aMenu} style={{ margin: "0px", padding: "0px"}}/>, desktopContent: <img src={g2aMenu} style={{ zIndex: "8"}}/>, id: 7, additionalDesktopStyle: {backgroundColor: "#01abe8"}, stopHover: true },
];

const NavBar = ({ mobileMenuProductOpened, onClose }) => {
  const [openPizzasMenu, setOpenPizzasMenu] = useState(false);
  const [openBaguettesMenu, setOpenBaguettesMenu] = useState(false);
  const [mobileNavbarPopoverOpen, setMobileNavbarPopoverOpen] = useState(false);
  const [showPizzasMobileMenu, setShowPizzasMobileMenu] = useState(false);
  const [showBaguettesMobileMenu, setShowBaguettesMobileMenu] = useState(false);
  const { width } = useWindowSize();
  const history = useHistory();

  const isMobile = width < NAVBAR_MOBILE_WIDTH;

  useEffect(() => {
    if (mobileMenuProductOpened) {
      setMobileNavbarPopoverOpen(true);

      if(mobileMenuProductOpened === "pizza"){
        setShowPizzasMobileMenu(true);
      }else if(mobileMenuProductOpened === "baguette"){
        setShowBaguettesMobileMenu(true);
      }
    }
  }, [mobileMenuProductOpened, onClose]);

  const toggle = () => {
    if (mobileNavbarPopoverOpen) {
      setShowPizzasMobileMenu(false);
      setShowBaguettesMobileMenu(false);
      onClose();
      document.body.classList.remove("modal-open");
    }
    setMobileNavbarPopoverOpen(!mobileNavbarPopoverOpen);
  };

  const navigationHandler = (id, device) => {
    if (id === 1 && device === "mobile") {
      setShowPizzasMobileMenu(true);
    }else if(id === 2 && device === "mobile"){
      setShowBaguettesMobileMenu(true);
    }else if (id === 5) {
      document.getElementById("footer").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      var newPath = generateHref(id)
      history.push({
        pathname: newPath,
      });
      window.scrollTo(0, 0);
    }
  };

  const logoGuseppeHandler = () => {
    history.push({
      pathname: "/",
    });
    window.scrollTo(0, 0);
  };

  const openProductsHandler = (id) => {
    if (id === 1) {
      setOpenPizzasMenu(true);
    } else {
      setOpenPizzasMenu(false);
    }

    if (id === 2) {
      setOpenBaguettesMenu(true);
    } else {
      setOpenBaguettesMenu(false);
    }
  };

  const generateHref = (id) => {
    if (id === 3) {
      return "/znajdz-sklep";
    }
    if (id === 4) {
      return "/jak-upiec";
    }
    if (id === 5) {
      return "/dowiedz-sie-wiecej";
    }
    if (id === 6) {
      return "/#footer";
    }
    return "/";
  };

  const createNavPosition = (position) => {
    if (position.submenus) {
      return <DropdownMenu key={position.id}
                           menuTitle={position.desktopContent}
                           submenus={position.submenus}/>
    }

    return (
      // eslint-disable-next-line
      <a
        key={position.id}
        className="navigationElement"
        onClick={() => navigationHandler(position.id, "desktop")}
        onMouseEnter={() => openProductsHandler(position.id)}
        href={generateHref(position.id)}
        style={position.additionalDesktopStyle}
      >
        <li style={{zIndex: "10"}}>
          {position.desktopContent}
        </li>
        {(!position.stopHover && <div
          className="navigationElementImage"
          style={
            (openPizzasMenu && position.id === 1) || (openBaguettesMenu && position.id === 2)
              ? { width: "100%", border: "px solid #a71814" }
              : null
          }
        />)}
      </a>
    );
  }

  const createMobileNavPosition = (position) => {
    return (
      <li
        onClick={() =>
          navigationHandler(position.id, "mobile")
        }
        key={position.id}
        style={{ ...position.additionalMobileStyle, marginBottom: "5px" }}
      >
        {position.mobileContent}
      </li>
    );
  }

  const Logo = (
    <div
      className="logoContainer"
      onMouseEnter={() => {
        setOpenPizzasMenu(false);
      }}
    >
      <div>
        <a
          href="https://www.oetker.pl/"
          target="_blank"
          rel="noopener noreferrer"
          style={{display: "inline"}}
        >
          <img
            className="logoDrOetker"
            alt="DrOetker"
            src={`${process.env.PUBLIC_URL}/images/logo-dr-oetker.png`}
          />
        </a>
      </div>

      <img
        src={`${process.env.PUBLIC_URL}/images/logo-guseppe.png`}
        alt="Logo Guseppe"
        className="logoGuseppe"
        onClick={logoGuseppeHandler}
      />
    </div>
  );

  const DesktopNavigationPositions = (
    <ul className="navigationList">
      {navigationPositions.map((position) => createNavPosition(position))}
    </ul>
  );

  const SocialMediaLinks = (
    <div className="socialMedia">
      <a
        className="FacebookButton"
        href="https://www.facebook.com/PizzaGuseppe/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/facebook.png`}
          alt="Facebook"
        />
      </a>
      <a
        href="http://m.me/PizzaGuseppe/"
        target="_blank"
        rel="noopener noreferrer"
        className="MessangerButton"
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/messanger.png`}
          alt="Messenger"
        />
      </a>
      <a
        className="InstagramButton"
        href="https://www.instagram.com/pizzaguseppe/?hl=pl"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/instagram.png`}
          alt="Instagram"
        />
      </a>
      <a
        className="YoutubeButton"
        href="https://www.youtube.com/channel/UC60DjNZjVmFye8VKmt2NZPw"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/youtube.png`}
          alt="Youtube"
        />
      </a>
    </div>
  );

  const BurgerMenuIcon = (
    <div
      className="burgerMenu"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/tlo-menu.png)`,
      }}
    >
      {mobileNavbarPopoverOpen ? (
        <img
          src={`${process.env.PUBLIC_URL}/images/BurgerMenuModal/x.png`}
          alt="X"
        />
      ) : (
        <>
          {Array.from({length: 3}).map((_, index) => (
            <img
              key={index}
              src={`${process.env.PUBLIC_URL}/images/burger-menu-line.png`}
              alt="Linia"
            />
          ))}
        </>
      )}
    </div>
  );

  const BurgerMenuBody = (
    <Popover
      placement="bottom"
      isOpen={mobileNavbarPopoverOpen}
      target="Popover1"
      toggle={toggle}
      style={{ backgroundColor: "#a71814" }}
    >
      <PopoverBody>
        <ul className="burgerNavigation">
          {navigationPositions.map((position) => createMobileNavPosition(position))}
        </ul>
      </PopoverBody>
    </Popover>
  )

  return (
    <header
      className="header"
    >
      {Logo}
      <div
        className="navigation"
        onMouseEnter={() => {
          setOpenPizzasMenu(false);
        }}
      >
        {!isMobile && DesktopNavigationPositions}
        {SocialMediaLinks}
        {isMobile && (
          <div className="menu">
            <Button
              id="Popover1"
              type="button"
              style={{ backgroundColor: "transparent" }}
              onClick={toggle}
            >
              {BurgerMenuIcon}
            </Button>
            {showPizzasMobileMenu && <PizzasList setPopoverOpen={setMobileNavbarPopoverOpen} />}
            {showBaguettesMobileMenu && <BaguettesList setPopoverOpen={setMobileNavbarPopoverOpen} /> }
            {(!showPizzasMobileMenu && !showBaguettesMobileMenu) &&  BurgerMenuBody}
          </div>
        )}
      </div>
      {!isMobile && <PizzasModal open={openPizzasMenu} setOpen={setOpenPizzasMenu} />}
      {!isMobile && <BaguettesModal open={openBaguettesMenu} setOpen={setOpenBaguettesMenu} />}
    </header>
  );
};

export default NavBar;
