import React from "react";
import Slider from "../Slider/Slider";
import "./home.scss";

const Home = ({ handleMobileMenuProductOpened }) => {
  return (
    <div className="home">
      <div className="backgroundImage">
        <Slider handleMobileMenuProductOpened={handleMobileMenuProductOpened} />
      </div>
    </div>
  );
};

export default Home;
