import React, { useState, useEffect } from "react";
import { pizzas } from "../../../constants";
import "./PizzasModal.scss";

const PizzasModal = ({ open, setOpen }) => {
  const [imagesReady, setImagesReady] = useState(false);

  function allLoaded() {
    setImagesReady(true);
  }

  let number = 0;

  useEffect(() => {
    pizzas.forEach((pizza) => {
      const img = new Image();
      img.src = pizza.modalPizza;
      img.onload = () => {
        number++;
        if (number === pizzas.length) {
          allLoaded();
        }
      };
    });
  }, [number]);

  const onClick = () => {
    window.scroll({
      top: 0,
      left: 0,
    });
  };

  return open ? (
    <div className="pizzasModal">
      <div className="pizzasContainer">
        {pizzas.map((pizza) => {
          return (
            <a
              className="pizzaContainer"
              key={pizza.id}
              onClick={() => onClick()}
              href={pizza.path}
            >
              {imagesReady ? (
                <div
                  className="pizzaModalImage"
                  style={{
                    backgroundImage: `url(${pizza.modalPizza})`,
                  }}
                />
              ) : null}
              {pizza.name}
            </a>
          );
        })}
        {/* workaround for removed pizza and proper layout */}
        <div className="pizzaContainer"/>
        <div className="pizzaContainer"/>
      </div>
    </div>
  ) : null;
};

export default PizzasModal;
