import React, { useState, useEffect } from "react";
import {baguettes} from "../../../constants";
import "./BaguettesModal.scss";

const BaguettesModal = ({ open }) => {
  const [imagesReady, setImagesReady] = useState(false);

  function allLoaded() {
    setImagesReady(true);
  }

  let number = 0;

  useEffect(() => {
    baguettes.forEach((baguette) => {
      const img = new Image();
      img.src = baguette.modalBaguette;
      img.onload = () => {
        number++;
        if (number === baguettes.length) {
          allLoaded();
        }
      };
    });
  }, [number]);

  const onClick = () => {
    window.scroll({
      top: 0,
      left: 0,
    });
  };

  return open ? (
    <div className="baguettesModal">
      <div className="baguettesContainer">
        {baguettes.map((baguette) => {
          return (
            <a
              className="baguetteContainer"
              key={baguette.id}
              onClick={() => onClick()}
              href={baguette.path}
            >
              {imagesReady && (
                <div
                  className="baguetteModalImage"
                  style={{
                    backgroundImage: `url(${baguette.modalBaguette})`,
                  }}
                ></div>
              )}
              {baguette.name}
            </a>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default BaguettesModal;
