import React, {Suspense} from "react";
import "./App.scss";
import * as ScrollMagic from "scrollmagic";
import {TimelineMax, TweenMax} from "gsap";
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
import Main from "./pages/Main/Main";
import SalamiSubpage from "./pages/SalamiSubpage/SalamiSubpage";
import {Helmet} from "react-helmet";
// import Cookies from "./components/Cookies/Cookies";
import NewCookies from "./components/Cookies/newCookies";
import {Route, Switch} from "react-router-dom";
import Loader from "./components/Loader/Loader";

const BaguetteMushroomSubpage = React.lazy(() => import("./pages/baguette/Mushroom/MushroomSubpage"));
const BaguetteSalamiSubpage = React.lazy(() => import("./pages/baguette/Salami/SalamiSubpage"));
const BaguetteTomatoCheeseSubpage = React.lazy(() => import("./pages/baguette/TomatoCheese/TomatoCheeseSubpage"));

const ChorizoGreenPepperSubpage = React.lazy(() => import("./pages/ChorizoGreenPepper/ChorizoGreenPepperSubpage"));
const HamMushroomSubpage = React.lazy(() => import("./pages/HamMushroom/HamMushroomSubpage"));
const CheeseSubpage = React.lazy(() => import("./pages/Cheese/CheeseSubpage"));
// const CheeseHotSubpage = React.lazy(() => import("./pages/CheeseHot/CheeseHotSubpage"));
const HamChorizoSubpage = React.lazy(() => import("./pages/HamChorizo/HamChorizoSubpage"));
const ChickenSubpage = React.lazy(() => import("./pages/Chicken/ChickenSubpage"));
const HamSubpage = React.lazy(() => import("./pages/Ham/HamSubpage"));
const KebabSubpage = React.lazy(() => import("./pages/Kebab/KebabSubpage"));
const HamGarlicSubpage = React.lazy(() => import("./pages/HamGarlic/HamGarlicSubpage"));
const HawaiiSubpage = React.lazy(() => import("./pages/Hawaii/HawaiiSubpage"));
const CheddarSubpage = React.lazy(() => import("./pages/SalamiCheddarSauce/CheddarSubpage"))
// const ChickenGrilledVegetablesSubpage = React.lazy(() => import("./pages/ChickenGrilledVegetables/ChickenGrilledVegetablesSubpage"));
const FindShop = React.lazy(() => import("./pages/FindShop/FindShop"));
const HowToBake = React.lazy(() => import("./pages/HowToBake/HowToBake"));
const LearnMore = React.lazy(() => import("./pages/LearnMore/LearnMore"));
// const G2A = React.lazy(() => import("./pages/G2A/G2A"));
// const G2ATerms = React.lazy(() => import("./pages/G2A/G2ATerms"));
const MobileGame = React.lazy(() => import("./pages/Game/Game"));
const UnknownPage = React.lazy(() => import("./pages/Unknown/Unknown"));

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

const routes = [
  {
    path: "/",
    title: "Guseppe, pizza mrożona, która wciąga! | Dr. Oetker",
    content: "Guseppe to pizza mrożona na pysznym cieście 🍕,\npuszystym w środku i chrupiącym na zewnątrz,\n ze składnikami ułożonymi aż po brzegi. Guseppe wciąga!",
    page: <Main/>
  },
  {
    path: "/pizza-chorizo-green-pepper",
    title: "Pizza mrożona Guseppe Shrek z chorizo i zieloną papryką (Chorizo & Green Pepper)",
    content: "Pizza mrożona Guseppe Shrek z chorizo, zieloną papryką i cebulą",
    page: <ChorizoGreenPepperSubpage/>
  },
  {
    path: "/pizza-salami",
    title: "Pizza mrożona Guseppe Salami",
    content: "Pizza mrożona Guseppe Salami 🍕 o wyrazistym smaku ostrego salami,\nwzbogacona papryką pepperoni i soczystą kukurydzą. Wyrazisty smak dla\nodważnych!",
    page: <SalamiSubpage/>
  },
  {
    path: "/pizza-szynka-pieczarka",
    title: "Pizza mrożona Guseppe Szynka & Pieczarka (Ham & Mushroom)",
    content: "Pizza mrożona Guseppe Szynka & Pieczarka 🍕. Pyszna kompozycja zawsze lubianej szynki i aromatycznych pieczarek, czerwonej cebulki, ziół i przypraw. Koniecznie sprawdź!",
    page: <HamMushroomSubpage/>
  },
  {
    path: "/pizza-4-sery",
    title: "Pizza mrożona Guseppe 4 sery (4 Cheese)",
    content: "Pizza mrożona Guseppe 4 sery 🍕 to prawdziwa serowa uczta. Niepowtarzalna kompozycja serów: Edamskiego, Mozzarella, Tylżyckiego i sera pleśniowego.",
    page: <CheeseSubpage/>
  },
  // { path: "/pizza-4-sery-hot", title: "Pizza mrożona Guseppe 4 cheese HOT", content: "Pizza mrożona Guseppe 4 sery 🍕 to pikantna pizza z pyszną kompozycją serów: Edamskiego, Mozzarella, Tylżyckiego i sera pleśniowego", page: <CheeseHotSubpage/> },
  {
    path: "/pizza-szynka-chorizo",
    title: "Pizza mrożona Guseppe Ham & Chorizo smak Farmerska",
    content: "Pizza mrożona Guseppe z kompozycją dwóch wędlin: aromatycznej szynki i wyraźnego chorizo oraz dodatkiem słodkiej kukurydzy i ostrej cebuli. Smakuje jak robiona w domu! ",
    page: <HamChorizoSubpage/>
  },
  {
    path: "/pizza-kurczak-curry",
    title: "Pizza mrożona Guseppe ChorizoGreenPepperMain Curry",
    content: "Pizza mrożona Guseppe z kurczakiem 🍕 w orientalnej przyprawie\ncurry, wzbogacona imbirem, mleczkiem kokosowym, papryką, czosnkiem, chilli\ni kardamonem.",
    page: <ChickenSubpage/>
  },
  {
    path: "/pizza-szynka",
    title: "Pizza mrożona Guseppe z szynką (Ham)",
    content: "Pizza mrożona Guseppe z szynką 🍕, której smak został wzbogacony świeżą\ncebulą, soczystą czerwoną papryką i aromatycznymi ziołami. Klasyk wśród\npizz, który smakuje każdemu!",
    page: <HamSubpage/>
  },
  {
    path: "/pizza-kebab",
    title: "Pizza mrożona Guseppe Kebab",
    content: "Pizza mrożona Guseppe Kebab 🍕 to orientalne połączenie smakowe w stylu\ntureckim. Idealna kompozycja mięsa wołowego, papryki, grillowanego\nbakłażana i czerwonej cebuli.",
    page: <KebabSubpage/>
  },
  // {path: "/pizza-kurczak-grillowane-warzywa", title:"Pizza mrożona Guseppe ChorizoGreenPepperMain & Grilled Vegetables", content:"Pizza mrożona Guseppe z kurczakiem i grillowanymi warzywami 🍕 Pizza, która spełni oczekiwania wszystkich amatorów kurczaka, grillowania i oczywiście pizzy!", page: <ChickenGrilledVegetablesSubpage />}
  {
    path: "/pizza-szynka-sos-czosnkowy",
    title: "Pizza mrożona Guseppe z szynką i sosem czosnkowym (Ham &amp; Garlic\nSauce)",
    content: "Pizza mrożona Guseppe z szynką i sosem czosnkowym 🍕 to unikalna\nkompozycja pizzy z lubianą szynką, sosem czosnkowym, pomidorkami\nkoktajlowymi oraz czerwoną cebulką.",
    page: <HamGarlicSubpage/>
  },
  {
    path: "/pizza-hawaii",
    title: "Pizza mrożona Guseppe Hawaii",
    content: "Guseppe Hawaii to pizza mrożona 🍕 , której smak tworzy kompozycja delikatnej szynki, słodkiego ananasa, ciągnącego się sera i wyrazistego pomidorowego sosu.",
    page: <HawaiiSubpage/>
  },
  {
    path: "/pizza-salami-cheddar",
    title: "Pizza mrożona Guseppe Salami & Cheddar Sauce",
    content: "Guseppe Salami & Cheddar Sauce to wyjątkowe połączenie lekko pikantnego salami i podwójnej porcji sera cheddar - na obłożeniu i w sosie.\nCałość wzbogacona zieloną papryką i doprawiona aromatycznymi ziołami. Wyraziste salami oraz intensywnie chedarowy sos sprawiają, że ta pizza to przepysznie doznanie w każdym kęsie. ",
    page: <CheddarSubpage/>
  },
  {
    path: "/baguette-salami",
    title: "Zapiekanka Guseppe Baguette Salami",
    content: "Zapiekanka mrożona Guseppe o smaku salami, z chrupiącą bagietką",
    page: <BaguetteSalamiSubpage/>
  },
  {
    path: "/baguette-mushroom",
    title: "Zapiekanka Guseppe Baguette Mushroom",
    content: "Zapiekanka mrożona Guseppe z pieczarkami i sosem śmietanowym, z chrupiącą bagietką",
    page: <BaguetteMushroomSubpage/>
  },
  {
    path: "/baguette-tomato-cheese",
    title: "Zapiekanka Guseppe Tomato & Cheese",
    content: "Zapiekanka mrożona Guseppe z trzema rodzajami sera, z chrupiącą bagietką",
    page: <BaguetteTomatoCheeseSubpage/>
  },
  {
    path: "/znajdz-sklep",
    title: "Sprawdź gdzie kupisz mrożoną pizzę Guseppe! | Dr. Oetker",
    content: "Skorzystaj z wyszukiwarki sklepów, sprawdź gdzie kupisz ulubioną mrożoną\npizzę Guseppe w swojej okolicy!",
    page: <FindShop/>
  },
  {
    path: "/dowiedz-sie-wiecej",
    title: "Dowiedz się więcej o pizzy Guseppe! | Dr. Oetker",
    content: "Sprawdź w jaki sposób tworzona jest pyszna pizza Guseppe! 🍕",
    page: <LearnMore/>
  },
  // {path: "/promocja-g2a", title: "G2A | Dr. Oetker", content: "Sprawdź kody zniżkowe G2A na pudełkach Guseppe! 🍕", page: <G2A />},
  // {path: "/g2a", title: "G2A | Dr. Oetker", content: "", page: <G2ATerms />},
  {
    path: "/jak-upiec",
    title: "Jak upiec mrożoną pizzę Guseppe? | Dr. Oetker",
    content: "Sprawdź jak upiec mrożoną pizzę Guseppe, zajrzyj do naszej instrukcji pieczenia! 🍕",
    page: <HowToBake/>
  },
  {
    path: "/gra",
    title: "Pizza mrożona Guseppe – gra łap składniki | Dr. Oetker",
    content: "Zagraj w łapanie składników do pizzy, sprawdź swój refleks. Poznaj naszą grę Guseppe!",
    page: <MobileGame/>
  },
  {path: "*", title: "Guseppe, pizza mrożona, która wciąga! | Dr. Oetker", content: "", page: <UnknownPage/>},
]

function App() {
  return (
    <div className="App">
      <Switch>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact
            render={() => (
              <Suspense
                fallback={
                  <div>
                    <Loader/>
                  </div>
                }
              >
                <Helmet>
                  <title>{route.title}</title>
                  <meta
                    name="description"
                    content={route.content}
                  />
                </Helmet>
                {route.page}
              </Suspense>
            )}
          />
        ))}
      </Switch>
      <NewCookies/>
    </div>
  );
}

export default App;
