import React, { useState, useEffect } from "react";
import Underline from "../../../../pages/SalamiSubpage/components/Informations/images/czerwone-podkreslenie.png";
import * as ScrollMagic from "scrollmagic";
import gsap from "gsap";
import Background from "../../../../images/Footer/mobile/bg-min.png";
import PizzaSlice from "../../../../images/Footer/salami-kawalek.webp";
import PizzaSliceFallback from "../../../../images/Footer/png/salami-kawalek.png";
import Basil from "../../../../images/Footer/mobile/bazylia-min.png";
import Tomato from "../../../../images/Footer/mobile/pomidor-min.png";
import LogoDrOetker from "../../../../images/Footer/mobile/logo-oetker-min.png";
import "../../Footer.scss";

const FooterMobile = () => {
  // eslint-disable-next-line
  const [scrollMagic, setScrollMagic] = useState({
    controller: new ScrollMagic.Controller(),
    timelineOne: gsap.timeline(),
  });
  const { controller, timelineOne } = scrollMagic;

  useEffect(() => {
    // footer animation
    timelineOne.staggerFromTo(
      ".contactContainer",
      0.5,
      { y: "120px", opacity: 0 },
      { y: "0", opacity: 1 }
    );
    new ScrollMagic.Scene({
      triggerElement: ".footerMobileAnimationTrigger",
      triggerHook: 0.95,
    })
      .setTween(timelineOne)
      .addTo(controller);
    // eslint-disable-next-line
  }, []);

  const openCookies = (e) => {
    e.preventDefault();
    window.cookiesComponent.openLongCookies();
  };

  return (
    <div className="footerMobileAnimationTrigger">
      <div className="contactContainer" id="footer">
        <div
          style={{
            margin: "40px",
            marginTop: "100px",
          }}
        >
          <div>
            <img src={LogoDrOetker} alt="DrOetker" style={{ width: "80px" }} />

            <img src={Basil} alt="Bazylia" className="contactBasil" />

            <img src={Tomato} alt="Pomidor" className="contactTomato" />
          </div>
          <p className="consumerService">Serwis Konsumenta</p>
          <div
            style={{ backgroundImage: `url(${Underline})` }}
            alt="Linia"
            className="consumerServiceUnderline"
          />
          <p className="contactIntro">
            Czekamy na Twoje pytania, prośby i&nbsp;sugestie. Poniżej znajdziesz
            adres.
          </p>
          <p className="address">
            Dr. Oetker Polska Sp. z o.o. Serwis Konsumenta ul. Adm. Dickmana
            14/15 80-339 Gdańsk
          </p>
          <p className="phone">Infolinia:</p>
          <div
            style={{ backgroundImage: `url(${Underline})` }}
            className="phoneUnderline"
          />
          <a href="tel:585111001" className="phoneNumber">
            585 111 001*
          </a>
          <div
            style={{ backgroundImage: `url(${Underline})` }}
            className="phoneNumberUnderline"
          />
          <p className="email">
            email: <strong>serwis@oetker.pl</strong> | faks:{" "}
            <strong>585 541 550</strong>
          </p>
          <p className="info">
            *Infolinia czynna jest od poniedziałku do piątku w godz. 10-16.
            <br/>
            Koszt połączenia zgodnie z&nbsp;cennikiem Operatora.
          </p>
          <p className="copyright">
            @ Copyright 2023 Dr. Oetker Polska Sp. z o.o. Wszelkie prawa
            zastrzeżone. <a target="_blank" rel="noreferrer" className="inlineLink" href={"https://www.oetker.pl/pl-pl/dodatkowe-informacje/polityka-prywatnosci"}>Polityka prywatności</a> | <a className="inlineLink" href='/' onClick={openCookies}>Ustawienia plików cookies</a>.
          </p>
        </div>
        <div
          className="pizzaSliceContainer"
          style={{
            backgroundImage: `url(${Background})`,
          }}
        >
          <picture>
            <source srcSet={PizzaSlice} type="image/webp" />
            <source srcSet={PizzaSliceFallback} type="image/png" />
            <img alt="Salami kawalek pizzy" className="contactPizzaSlice" />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default FooterMobile;
