import useWindowSize from "../../../Hooks/UseWindowSize";
import {MOBILE_WIDTH} from "../../../constants";
import useMobileMenu from "../../../Hooks/UseMobileMenu";
import NavBar from "../../NavBar/NavBar";
import React from "react";
import Footer from "../../Footer/Footer";
import {FixedMobileMenuSelect} from "../MobileMenuSelect/MobileMenuSelect";

/**
 * Wrapper component that renders children inside NavBar and Footer components.
 *
 * @param renderFixedMobileMenu - if true FixedMobileMenuSelect will be rendered (should be used on pizza/baguettes pages)
 * @param children
 * @returns {Element}
 */
const NavbarFooterWrapper = ({renderFixedMobileMenu, children}) => {
  const {width} = useWindowSize();
  const isMobile = width <= MOBILE_WIDTH;
  const {mobileMenuProductOpened, handleCloseNavbar, handleMobileMenuProductOpened} = useMobileMenu();
  const shouldRenderMobileMenu = renderFixedMobileMenu && isMobile && !mobileMenuProductOpened;

  return (
    <>
      <NavBar
        mobileMenuProductOpened={mobileMenuProductOpened}
        onClose={handleCloseNavbar}
      />
      {children}
      {shouldRenderMobileMenu &&
        <FixedMobileMenuSelect mobileMenuProductOpened={mobileMenuProductOpened}
                               handleMobileMenuProductOpened={handleMobileMenuProductOpened}/>
      }

      <Footer/>
    </>
  )
}

export default NavbarFooterWrapper;
