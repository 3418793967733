import "./DropdownMenu.scss";

const DropdownMenu = ({menuTitle, submenus}) => {
  return (
    <div className="dropdownMenuContainer">
      {menuTitle}
      <div className="submenusContainer">
        {submenus.map((submenu) => (
          <a key={submenu.link}
             href={submenu.link}
             className={`${submenu.desktopContent === "Zapiekanki" ? "submenuBaguetteEntry" : "submenuEntry"}`}
          >
            <h1>
              {submenu.desktopContent}
            </h1>
          </a>
        ))}
      </div>
    </div>
  )
}

export default DropdownMenu;
