/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Main from "./components/Main/Main";
import Instruction from "./components/Instruction/Instruction";
import Informations from "./components/Informations/Informations";
import useWindowSize from "../../Hooks/UseWindowSize";
import "./SalamiSubpage.scss";
import {MOBILE_WIDTH} from "../../constants";
import NavbarFooterWrapper from "../../components/common/NavbarFooterWrapper/NavbarFooterWrapper";

function SalamiSubpage() {
  const {width} = useWindowSize();
  const isMobile = width <= MOBILE_WIDTH;

  return (
    <div className="subPage">
      <NavbarFooterWrapper renderFixedMobileMenu={true}>
        <Main/>
        <Instruction/>
        {isMobile && <Informations/>}
      </NavbarFooterWrapper>
    </div>
  );
}

export default SalamiSubpage;
