import React from "react";
import "./MobileMenuSelect.scss"

const MOBILE_MENU_SELECT_BACKGROUND = `${process.env.PUBLIC_URL}/images/slider/menu/mobile-menu-select-background.png`;

const MobileMenuSelect = ({mobileMenuProductOpened, handleMobileMenuProductOpened}) => {
  const handleMobileMenuPizzaClicked = (e) => {
    handleMobileMenuProductOpened(e, "pizza");
  }

  const handleMobileMenuBaguetteClicked = (e) => {
    handleMobileMenuProductOpened(e, "baguette");
  }

  return (
    !mobileMenuProductOpened && (
      <div className="mobileMenuSection">
        <div className="mobileMenuContainer">
          <div className="mobileMenuSelectItem"
               style={{backgroundImage: `url(${MOBILE_MENU_SELECT_BACKGROUND})`}}
               onClick={handleMobileMenuPizzaClicked}
          >
            <p>
              wybierz smak pizzy
            </p>
          </div>
          <p>
            /
          </p>
          <div className="mobileMenuSelectItem"
               style={{backgroundImage: `url(${MOBILE_MENU_SELECT_BACKGROUND})`}}
               onClick={handleMobileMenuBaguetteClicked}
          >
            <p>
              wybierz smak zapiekanki
            </p>
          </div>
        </div>
      </div>
    )
  );
}

export const FixedMobileMenuSelect = ({mobileMenuProductOpened, handleMobileMenuProductOpened}) => {
  return (
    <div className="mobileMenuFixedPositionContainer">
      <MobileMenuSelect mobileMenuProductOpened={mobileMenuProductOpened}
                        handleMobileMenuProductOpened={handleMobileMenuProductOpened}/>
    </div>
  );
}

export default MobileMenuSelect;
