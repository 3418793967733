import React, {useState} from "react";
import "../Menu.scss";
import {pizzas} from "../../../constants";

const PizzaMenu = ({pizza, activeIndex}) => {
  const defaultFooterItem = `${process.env.PUBLIC_URL}/images/slider/selected/footer-menu-item.png`;
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const isPizzaMenuItemActive = activeIndex === pizza;
  const isPizzaMenuItemHovered = hoveredIndex === pizza;

  const sliderNavigationHandler = () => {
    window.scroll({
      top: 0,
      left: 0,
    });
  };

  const getItemBackgroundImage = () => {
    if (isPizzaMenuItemActive || isPizzaMenuItemHovered) {
      return `url(${pizzas[pizza].pizzaSelectedFooterItem})`
    }

    return `url(${defaultFooterItem})`;
  }

  const isShrekPizza = (pizzaId) => {
    return pizzaId === -1;
  }

  const AdditionalShrekEarsElement = (
    <div className="additionalShrekEarsImage"
         style={{
           backgroundImage: `url(${process.env.PUBLIC_URL}/images/slider/selected/additional/shrek-ears.png)`
         }}
    />
  );

  return (
    <a href={pizzas[pizza].path} className={isPizzaMenuItemActive ? 'slick-active' : ''}
       style={{textDecoration: "none"}}>
      <div>
        {isShrekPizza(pizzas[pizza].id) && AdditionalShrekEarsElement}
        <div
          className="footerMenuItem"
          onClick={() => sliderNavigationHandler(pizza)}
          style={{
            backgroundImage: getItemBackgroundImage()
          }}
          onMouseEnter={() => setHoveredIndex(pizza)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          {pizzas[pizza].name}
        </div>
      </div>
    </a>
  );
};

export default PizzaMenu;
