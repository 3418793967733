import React, {useState} from "react";
import "../Menu.scss";
import {baguettes, pizzas} from "../../../constants";

const BaguetteMenu = ({baguetteIndex, activeIndex}) => {
  const defaultFooterItem = `${process.env.PUBLIC_URL}/images/slider/selected/footer-menu-item.png`;
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const isBaguetteMenuItemActive = activeIndex - pizzas.length === baguetteIndex;
  const isBaguetteMenuItemHovered = hoveredIndex === baguetteIndex;

  const sliderNavigationHandler = () => {
    window.scroll({
      top: 0,
      left: 0,
    });
  };

  const getItemBackgroundImage = () => {
    if (isBaguetteMenuItemActive || isBaguetteMenuItemHovered) {
      return `url(${baguettes[baguetteIndex].baguetteSelectedFooterItem})`
    }

    return `url(${defaultFooterItem})`;
  }

  return (
    <a href={baguettes[baguetteIndex].path} style={{textDecoration: "none"}}
       className={isBaguetteMenuItemActive ? 'slick-active' : ''}>
      <div
        className="footerMenuItem"
        onClick={() => sliderNavigationHandler(baguetteIndex)}
        style={{
          backgroundImage: getItemBackgroundImage()
        }}
        onMouseEnter={() => setHoveredIndex(baguetteIndex)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        {baguettes[baguetteIndex].name}
      </div>
    </a>
  );
};

export default BaguetteMenu;
