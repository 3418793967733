/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import gsap from "gsap";
import {baguettes, MOBILE_WIDTH, pizzas, products} from "../../constants";
import useWindowSize from "../../Hooks/UseWindowSize";
import Slider from "react-slick";
import "./Slider.scss";
import PizzaMenu from "../Menus/PizzaMenu/PizzaMenu";
import BaguetteMenu from "../Menus/BaguetteMenu/BaguetteMenu";
import MobileMenuSelect from "../common/MobileMenuSelect/MobileMenuSelect";

let tl = gsap.timeline({ paused: true });

function SamplePrevArrow(props) {
  const [prevButtonImg, setPrevButtonImg] = useState(
    `${process.env.PUBLIC_URL}/images/slider/zakreslenie-biale.png`
  );
  const [animation, setAnimation] = useState({});
  const { onClick, tl } = props;

  useEffect(() => {
    setAnimation(
      gsap.fromTo(
        ".prevButton",
        { scale: 0.99 },
        {
          scale: 0.8,
          paused: true,
          duration: 0.2,
          ease: "linear",
        }
      )
    );
  }, []);

  const handleClick = () => {
    if (tl.totalProgress() === 0) {
      animation.play().then(() => {
        animation.reverse();
      });

      onClick();
    }
  };

  return (
    <button
      className="prevButton"
      onClick={handleClick}
      onMouseEnter={() =>
        setPrevButtonImg(
          `${process.env.PUBLIC_URL}/images/gify/linia-owal-biala-anim.gif`
        )
      }
      onMouseLeave={() =>
        setPrevButtonImg(
          `${process.env.PUBLIC_URL}/images/slider/zakreslenie-biale.png`
        )
      }
    >
      <img
        src={`${process.env.PUBLIC_URL}/images/slider/strzalka-lewo.png`}
        alt="Strzalka lewo"
      />

      <p
        style={{
          fontSize: "16px",
          fontWeight: "700",
          color: "#fffad4",
          marginTop: "30px",
        }}
      >
        poprzednia
      </p>

      <img src={prevButtonImg} alt="Slider linia lewo" className="ovalLineAnimation" />
    </button>
  );
}

function SampleNextArrow(props) {
  const [nextButtonImg, setNextButtonImg] = useState(
    `${process.env.PUBLIC_URL}/images/slider/zakreslenie-czerwone.png`
  );
  const [animation, setAnimation] = useState({});
  const { onClick, tl } = props;
  const size = useWindowSize();
  const { width } = size;

  useEffect(() => {
    setAnimation(
      gsap.fromTo(
        ".nextButton",
        { scale: 0.99, y: "-50%", x: "-100%" },
        {
          scale: 0.8,
          paused: true,
          duration: 0.2,
          ease: "linear",
        }
      )
    );
  }, []);
  const handleClick = () => {
    if (tl.totalProgress() === 0) {
      animation.play().then(() => {
        animation.reverse();
      });
      onClick();
    }
  };

  return (
    <button
      className="nextButton"
      onClick={handleClick}
      onMouseEnter={() =>
        setNextButtonImg(
          `${process.env.PUBLIC_URL}/images/gify/linia-owal-czerwona-anim.gif`
        )
      }
      onMouseLeave={() =>
        setNextButtonImg(
          `${process.env.PUBLIC_URL}/images/slider/zakreslenie-czerwone.png`
        )
      }
    >
      <img
        src={`${process.env.PUBLIC_URL}/images/slider/strzalka-prawo.png`}
        alt="Strzalka prawo"
        style={width > MOBILE_WIDTH ? { minWidth: "89.1px" } : null}
      />

      <p
        style={{
          fontSize: "16px",
          fontWeight: "700",
          color: "#a71814",
          marginTop: "30px",
        }}
      >
        następna
      </p>

      <img src={nextButtonImg} alt="Slider linia prawo" className="ovalLineAnimation" />
    </button>
  );
}

const Fade = ({ handleMobileMenuProductOpened }) => {
  const size = useWindowSize();
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const [clickable, setClickable] = useState(true);
  const [imagesReady, setImagesReady] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const { width } = size;
  const isMobileWidth = width <= MOBILE_WIDTH;

  function allLoaded() {
    setImagesReady(true);
  }

  let number = 0;

  useEffect(() => {
    products.forEach((product) => {
      const img = new Image();
      img.src = product.productType === "pizza" ? product.pizzaBoxFallback : product.baguetteBoxFallback;
      img.onload = () => {
        number++;

        if (number === products.length) {
          allLoaded();
        }
      };
    });
  }, [number]);

  useEffect(() => {
    tl = gsap.timeline({ paused: true });
    if (!isMobileWidth && imagesReady) {
      tl.to(".sliderImg", { duration: 0.2, y: "5%", x: "5px" }).to(
        ".sliderImg",
        {
          duration: 1,
          y: "-100%",
          x: "-110px",
        }
      );
    }
  }, [isMobileWidth, imagesReady]);

  const onMouseEnterHandler = () => {
    if (!isMobileWidth) {
      tl.play();
    }
  };
  const onMouseLeaveHandler = () => {
    if (!isMobileWidth) {
      tl.reverse();
    }
  };

  const onSliderChange = (newIndex) => {
    if (tl.totalProgress() === 0) {
      setClickable(true);
      setIndex(newIndex);
    }
  };

  const onClick = () => {
    history.push({
      pathname: products[index].path,
    });
    window.scrollTo(0, 0);
  };

  const mapProductToSliderElement = (product) => {
    if(product.productType === "pizza"){
      const pizza = product;

      return (
        <div key={pizza.id} style={{ position: "relative" }}>
          <div
            className="sliderImgContainer"
            onMouseOver={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
          >
            {!isMobileWidth ? (
              <picture>
                <source srcSet={pizza.pizzaBox} type="image/webp" />
                <source srcSet={pizza.pizzaBoxFallback} type="image/png" />
                <img
                  style={{ zIndex: 10, position: "relative" }}
                  className="sliderImg"
                  alt={pizza.alt}
                  onClick={null}
                />
              </picture>
            ) : (
              <img
                src={pizza.pizzaBoxMobile}
                style={{ zIndex: 10, position: "relative" }}
                className="sliderImg"
                alt={pizza.alt}
                onClick={() => clickable && onClick()}
              />
            )}

            {(!isMobileWidth && imagesReady) && (
              <picture>
                <source srcSet={pizza.pizza} type="image/webp"/>
                <source srcSet={pizza.pizzaFallback} type="image/png"/>
                <img
                  className="sliderPizza"
                  alt={pizza.alt}
                  onClick={() => clickable && onClick()}
                />
              </picture>
            )}
          </div>
        </div>
      );
    }

    if(product.productType === "baguette"){
      const baguette = product;

      return <div key={baguette.id} style={{ position: "relative" }}>
        <div
          className="sliderImgContainer"
          onMouseOver={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
        >
          {!isMobileWidth ? (
            <picture>
              <source srcSet={baguette.baguetteBox} type="image/webp" />
              <source srcSet={baguette.baguetteBoxFallback} type="image/png" />
              <img
                style={{ zIndex: 10, position: "relative" }}
                className="sliderImg"
                alt={baguette.alt}
                onClick={null}
              />
            </picture>
          ) : (
            <img
              src={baguette.baguetteBoxMobile}
              style={{ zIndex: 10, position: "relative" }}
              className="sliderImg"
              alt={baguette.alt}
              onClick={() => clickable && onClick()}
            />
          )}

          {(!isMobileWidth && imagesReady) && (
            <picture>
              <source srcSet={baguette.baguette} type="image/webp"/>
              <source srcSet={baguette.baguetteFallback} type="image/png"/>
              <img
                className="sliderBaguette"
                alt={baguette.alt}
                onClick={() => clickable && onClick()}
              />
            </picture>
          )}
        </div>
      </div>
    }
  }

  const settings = {
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    prevArrow: <SamplePrevArrow tl={tl} />,
    nextArrow: <SampleNextArrow tl={tl} />,
    afterChange: onSliderChange,
    beforeChange: (_, next) => {
      setClickable(false);
      setActiveIndex(next);
    },
  };

  const DesktopSliderPagination = <>
    <div style={{display: "flex", flexDirection: "column"}}>
      <div className="pizzaPagination" style={{textAlign: "center", width: "100%"}}>
        <div style={{width: "100%", position: "absolute", bottom: "80px"}}>
          <p style={{
            color: "#a71814",
            fontSize: "27px",
            fontWeight: "bold",
            marginBottom: "0px"
          }}>
            Guseppe pizza
          </p>
        </div>
        <ul className="sliderDots" style={{bottom: "10px"}}>
          {pizzas.map((pizza, index) => (
            <PizzaMenu key={pizza.path} pizza={index} activeIndex={activeIndex}/>
          ))}
        </ul>
      </div>

      <div className="baguettesPagination" style={{textAlign: "center", width: "100%", marginBottom: "50px"}}>
        <div style={{width: "100%", position: "absolute", bottom: "-50px"}}>
          <p style={{
            color: "#a71814",
            fontSize: "27px",
            fontWeight: "bold",
            marginBottom: "0px"
          }}>
            Guseppe zapiekanki
          </p>
        </div>
        <ul className="sliderDots" style={{bottom: "-120px"}}>
          {baguettes.map((baguette, index) => (
            <BaguetteMenu key={baguette.path} baguetteIndex={index} activeIndex={activeIndex}/>
          ))}
        </ul>
      </div>
    </div>
  </>;

  return (
    <div className="sliderContainer">
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Slider {...settings}>
        {products.map((product) => mapProductToSliderElement(product))}
      </Slider>

      {!isMobileWidth && DesktopSliderPagination}

      {isMobileWidth && <MobileMenuSelect handleMobileMenuProductOpened={handleMobileMenuProductOpened} />}
    </div>
  );
};

export default Fade;
