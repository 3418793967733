import {useState} from "react";

const useMobileMenu = () => {
  const [mobileMenuProductOpened, setMobileMenuProductOpened] = useState(null);

  const handleCloseNavbar = () => {
    setMobileMenuProductOpened(null);
  };

  const handleMobileMenuProductOpened = (e, productName) => {
    e.preventDefault();
    setMobileMenuProductOpened(productName);
  };

  return {mobileMenuProductOpened, handleMobileMenuProductOpened, handleCloseNavbar};
}

export default useMobileMenu;
