import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { pizzas } from "../../../constants";
import "./PizzasList.scss";

const PizzasList = ({ setPopoverOpen }) => {
  const history = useHistory();
  const [imagesReady, setImagesReady] = useState(false);

  function allLoaded() {
    setImagesReady(true);
  }

  let number = 0;

  useEffect(() => {
    pizzas.forEach((pizza) => {
      const img = new Image();
      img.src = pizza.modalPizza;
      img.onload = () => {
        number++;
        if (number === pizzas.length) {
          allLoaded();
        }
      };
    });

    return () => {};
  }, [number]);

  const sliderNavigationHandler = (path) => {
    setPopoverOpen(false);
    history.push({
      pathname: path,
    });
    window.scroll({
      top: 0,
      left: 0,
    });
  };

  return (
    <div className="pizzaModal">
      <div
        className="burgerMenuPizzasContainer"
      >
        {pizzas.map((pizza) => {
          return (
            <div
              className="burgerMenuPizzaContainer"
              key={pizza.id}
              onClick={() => sliderNavigationHandler(pizza.path)}
            >
              {imagesReady ? (
                <div
                  className="pizzaImage"
                  style={{
                    backgroundImage: `url(${pizza.modalPizza})`,
                  }}
                ></div>
              ) : null}
              {pizza.name}
            </div>
          );
        })}
        {/* FOR LAYOUT PURPOSE */}
        <div className="burgerMenuPizzaContainer"></div>
      </div>
    </div>
  );
};

export default PizzasList;
