import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {baguettes} from "../../../constants";
import "./BaguettesList.scss";

const BaguettesList = ({ setPopoverOpen }) => {
  const history = useHistory();
  const [imagesReady, setImagesReady] = useState(false);

  function allLoaded() {
    setImagesReady(true);
  }

  let number = 0;

  useEffect(() => {
    baguettes.forEach((baguette) => {
      const img = new Image();
      img.src = baguette.modalBaguette;
      img.onload = () => {
        number++;
        if (number === baguettes.length) {
          allLoaded();
        }
      };
    });

    return () => {};
  }, [number]);

  const sliderNavigationHandler = (path) => {
    setPopoverOpen(false);
    history.push({
      pathname: path,
    });
    window.scroll({
      top: 0,
      left: 0,
    });
  };
  return (
    <div className="baguetteModal">
      <div
        className="burgerMenuBaguettesContainer"
      >
        {baguettes.map((baguette) => {
          return (
            <div
              className="burgerMenuBaguetteContainer"
              key={baguette.id}
              onClick={() => sliderNavigationHandler(baguette.path)}
            >
              {imagesReady && (
                <img className="baguetteImage" src={baguette.modalBaguette}/>
              )}
              {baguette.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BaguettesList;
